import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent {

  disClaimerText = "";
  currentUrl = this.location.path();

  @Output() toggleCheckDisclaimer = new EventEmitter<boolean>();
  @Input() errorDisclaimer = false;
  constructor(private location: Location) {
    if (this.currentUrl === '/clients-consult-gr') {
      this.disClaimerText = "Al continuar autorizo expresamente a APAP a que realice consultas al buró de crédito para fines de evaluación crediticia y certifico que los datos que estoy aportando son verídicos.";
    } else {
      this.disClaimerText = "Al continuar autorizo a APAP a que realice las consultas necesarias a mi buró de crédito y valido que los datos que estoy aportando son verídicos."
    }
  }

  onChange(checked: boolean) {
    this.toggleCheckDisclaimer.emit(checked);
  }

}
