import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-offert-dialog',
  templateUrl: './offert-dialog.component.html',
  styleUrls: ['./offert-dialog.component.scss'],
})
export class OffertDialogComponent {
  constructor(public dialogRef: MatDialogRef<OffertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }
}
