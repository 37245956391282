import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil, timeout } from 'rxjs';
import { AppConfigService } from 'src/app/shared/services/config/config.service';
import { IEnvironment } from 'src/app/shared/interfaces/enviroment.interface';

@Injectable({
  providedIn: 'root',
})
export class CreateFormShortService implements OnDestroy {
  private _baseURL: string;
  private urlAPI: string = 'api/grupo-ramos/';
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly http: HttpClient, private readonly _config: AppConfigService<IEnvironment>) {
    this._baseURL = `${this._config.getConfiguration()?.apiGatewayUrl}${this.urlAPI}`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public createClientRequest(data: any) {
    const url = this._baseURL + `requests/save-client-web`
    return this.http.post(url, data);
}
}
