export class ClientRequest {
    public cedula: string;
    public phone: string;
    public email: string;
    public productos: ProductDto[] = [];
    
}
export class ProductDto{
    public producto: string;
    public offerId: string;
}