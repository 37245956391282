<div class="ctn-modal">
    <img src={{data.img}} />
    <p [innerHTML]="data.title"></p>
    <span *ngIf="data.id == 1" mat-dialog-actions class="modal-client-btn">
       <div [innerHTML]="data.msg">
       </div>
    </span>
    <span *ngIf="data.id == 2" mat-dialog-actions class="modal-client-btn">
        <button class="modal-cient-btn-exit" mat-flat-button (click)="goToHomePage()">
            Salir
        </button>
        <button class="modal-client-btn-offer" mat-button (click)="gotToBeClient()">
            Solicitud de productos
        </button>
    </span>
    <span *ngIf="data.id == 3" mat-dialog-actions class="modal-client-btn">
        <button class="modal-cient-btn-exit" mat-button (click)="goToHomePage()">
            Si
        </button>
         <button class="modal-client-btn-offer" mat-flat-button (click)="onNoClick()">
            No
        </button>
    </span>
    <span *ngIf="data.id == 4" mat-dialog-actions class="modal-client-btn">
        <button class="modal-cient-btn-exit" mat-button (click)="goToConsultForm()">
            Si
        </button>
         <button class="modal-client-btn-offer" mat-flat-button (click)="onNoClick()">
            No
        </button>
    </span>
</div>
