<div class="bg-img">
<div class="card-container" *ngIf="!goNext">
	<div class="card">
		<section class="card-body">
			<h1 class="title">Completa tus <span>datos</span></h1>
			<h2 class="subtitle">Consulta el estatus aquí</h2>
			<form name="documentForm" autocomplete="off" #documentForm="ngForm"
			class="form"
				(ngSubmit)=" documentForm.valid && phoneCtr.valid && consultClient()">
				<div class="row justify-content-center">
					<mat-form-field class="item-container" appearance="outline">
						<mat-label>
							Documento de identidad
						</mat-label>
						<input [mask]="'000-0000000-0'" appMaskIdentification matInput placeholder="Documento" required
							type="text" name="doc" #doc="ngModel" [(ngModel)]="entryData.document" minlength="11" />
						<mat-icon matPrefix>badge</mat-icon>
						<mat-error *ngIf="doc.errors?.required">
							Este campo es obligatorio
						</mat-error>
					</mat-form-field>
				</div>
				<div class="row justify-content-center">
					<mat-form-field class="item-container" appearance="outline">
						<mat-label>
							Correo
						</mat-label>
						<input matInput placeholder="Email" required type="email" name="email" #email="ngModel"
							[(ngModel)]="entryData.email" email="true" />
						<mat-icon matPrefix>email</mat-icon>
						<mat-error *ngIf="email.errors?.required">
							Este campo es obligatorio
						</mat-error>
						<mat-error *ngIf="email.errors?.email">
							Debe escribir un correo válido
						</mat-error>
					</mat-form-field>

				</div>
				<div class="row justify-content-center">
					<mat-form-field class="item-container" appearance="outline">
						<mat-label>Teléfono</mat-label>
						<mat-icon matPrefix>phone</mat-icon>
						<input [mask]="'000-000-0000'" matInput [(ngModel)]="entryData.phone" class="input"
							[formControl]="phoneCtr" ngModel maxlength="12" />
						<mat-error *ngIf="phoneCtr.errors?.invalidAreaCode">
							Código de área inválido
						</mat-error>
						<mat-error *ngIf="phoneCtr.errors?.required">
							Número de teléfono obligatorio
						</mat-error>
					</mat-form-field>
				</div>
				<div class="item-last">
					<app-disclaimer [errorDisclaimer]="errorDisclaimer" (toggleCheckDisclaimer)="toggleCheckDisclaimer($event)"></app-disclaimer>
				</div>
				<!-- <div class="item-last">
					<mat-checkbox required name="check" class="item-container"
						(change)="toggleCheckDisclaimer($event.checked)">
						<span>
							Al continuar autorizo a APAP a que realice las consultas necesarias a mi buró de crédito y valido que los datos que estoy aportando son verídicos.
						</span>
						<mat-error *ngIf="errorDisclaimer">
							Debes aceptar los términos *
						</mat-error>

					</mat-checkbox>

				</div> -->
				<div class="btns">
					<button class="back-btn" type="button" mat-button (click)="openDialogRechazo()">
						Regresar
					</button>
					<button class="consult-btn" type="submit" mat-button>Siguiente</button>
				</div>
			</form>
		</section>
	</div>
</div>

<div *ngIf="goNext">
	<app-clients-form [clientData]="entryData" (goBackEvent)="goBack($event)" (savedEvent)="savedRequest($event)">
	</app-clients-form>
</div>
</div>
<ngx-loading [show]="loading"></ngx-loading>