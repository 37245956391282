import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appMaskIdentification]',
})
export class MaskIdentificationDirective {
	@HostListener('input', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;

		let trimmed = input.value.replace(/\s+/g, '');

		if (trimmed.length > 13) {
			trimmed = trimmed.substring(0, 13);
		}

		trimmed = trimmed.replace(/-/g, '');

		let numbers = [];
		numbers.push(trimmed.substring(0, 3));
		if (trimmed.substring(3, 10) !== '') {
			numbers.push(trimmed.substring(3, 10));
		}
		if (trimmed.substring(10, 12) != '') {
			numbers.push(trimmed.substring(10, 12));
		}
		input.value = numbers.join('-');
	}
}