import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConsultDocumentService } from '../service/consult-document.service';
import { RoutersEnum } from '../enums/routers.enums';
import { ResConsult } from 'src/app/interfaces/grupo-ramos-interfaces/res-consult.interface';
import { DialogModalComponent } from 'src/app/shared/components/dialog-modal/dialog-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { type_modal } from '../../helpers/msg-modal.helper';
import { UrlInterceptor } from '../../helpers/url-interceptor';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { OffertDialogComponent } from 'src/app/shared/components/offert-dialog/offert-dialog.component';
import { SendFormErrorDialogComponent } from '../send-form-error-dialog/send-form-error-dialog.component';

@Component({
  selector: 'app-clients-consult-gr',
  templateUrl: './clients-consult-gr.component.html',
  styleUrls: ['./clients-consult-gr.component.scss']
})
export class ClientsConsultGrComponent implements OnInit {

  consultDataForm!: FormGroup;
  showBannerAlert = false;
  isLoading = false;
  message = 'Error en al consultar';
  user: any;
  errorDisclaimer = false;
  isRight = false;
  urlData: any;
  constructor(
    private readonly formBuild: FormBuilder,
    private readonly authService: AuthService,
    private readonly consutDocumentService: ConsultDocumentService,
    private readonly router: Router,
    public  readonly dialog: MatDialog,
    public readonly  urlInterceptor: UrlInterceptor
  ) {}

  async ngOnInit(): Promise<void> {
    this.autenticar();
    await this.getUrlData();

    this.consultDataForm = this.initForm();
  }

  private async getUrlData() {
    this.urlData = await this.urlInterceptor.getParamsUrl()
  }

  openErrorDialog(message: string, code: number): void {
    const dialogRef = this.dialog.open(SendFormErrorDialogComponent, {
      width: '250px',
      data: { message, code },
    });
  }
  
  async onSubmit(): Promise<void> {
    if (!this.isRight) {
      this.errorDisclaimer = true;
      return;
    }
    this.isLoading = true;
    try {
      const res = await this.consutDocumentService.getData(this.consultDataForm.value.customerId);
      const { isSucceded, content } = res;
      if (isSucceded) {
        const resConsult: ResConsult = content;
        await this.redirectForm(resConsult);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        
        this.openErrorDialog(res?.error?.message || 'Se produjo un error al realizar la consulta. Inténtalo nuevamente.', res?.error?.code);
        setTimeout(() => {
          this.showBannerAlert = false;
        }, 6000);
        return;
      }
    } catch (error) {
      this.isLoading = false;

      return console.log(error, `Error al obtener data de consutDocumentService()`);
    }
  }

  public toggleCheckDisclaimer(checked: boolean) {
    this.isRight = checked;
    this.errorDisclaimer = !checked;
  }

  openDialogTC(offert: string) {
    const dialogRef = this.dialog.open(OffertDialogComponent, {
      width: '400px',
      data: { offert },
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        return Promise.resolve(result);
      });
  }

  async redirectForm(data: ResConsult): Promise<void> {
    if (data?.haveCCOffer) {
      const resp = await this.openDialogTC(data.offer[0]?.preApprovedAmount);
      if (resp) {
        await this.router.navigate([RoutesEnum.WebRequestForm], {
          state: { data: { ...data, idDocument: this.consultDataForm.value.customerId, channel: this.urlData } },
        });

        return;
      } else {
        location.reload();
      }
    }
    await this.router.navigate([RoutersEnum.WebRequestFormPage], {
      state: { data: { ...data, idDocument: this.consultDataForm.value.customerId, channel: this.urlData } },
    });
  }

  isValidField(name: string): boolean {
    const fieldName = this.consultDataForm.get(name);
    return !!fieldName?.invalid && !!fieldName?.touched;
  }

  initForm(): FormGroup {
    return this.formBuild.group({
      customerId: ['', [Validators.required]],
    });
  }

  private autenticar() {
    this.authService.generateToken().subscribe((data: any) => {
      localStorage.setItem('token', data.content.token);
    });
  }
  
  public openDialogRechazo(): void {
		this.dialog.open(DialogModalComponent, {
			data: type_modal.msgRechazarOferta,
		});
	}
}