<div class="d-flex justify-content-center text-center flex-column p-8">
  <div style="padding: 24px 0px">
    <img alt="credit_card" src="../../../../assets/img/icon-tc.svg" />
  </div>
  <div style="padding: 8px 0px">
    <p style="color: black; line-height: 120%"> 
      Tienes una Tarjeta Sirena APAP aprobada.  
    </p>
    <p style="color: black; line-height: 120%">Acéptala ahora.</p>
  </div>
  <div class="d-flex flex-row-reverse justify-content-around">
    <button class="btn" style="background: #ffcd00" mat-button (click)="closeDialog(true)">Me interesa</button>
    <button class="btn" style="border: 1px solid rgb(0, 0, 0)" mat-button (click)="closeDialog(false)">Regresar</button>
  </div>
</div>
