import { environment } from "src/environments/environment";

export class Globals {
    private readonly urlApiGateway = environment.apiURL

    private readonly a = {
        APIProducts: `${this.urlApiGateway}api-products/`
    }

    public urlApiProducts = this.a.APIProducts;
}