<nav>
  <img class="logo-web" src="{{navBarImg}}" alt="" >
  <img class="logo-mobile" src="./assets/img/logo-mobile.svg" alt="">
  <div [ngClass]="currentUrl === '/clients-consult-gr' || currentUrl === '/web-request-form' ? 'security-gr' : 'security'">
    <span>
      <mat-icon>lock</mat-icon>
      <p>
        {{ navBarSubTitle }}
      </p>  
    </span>
  </div>
</nav>

  