import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterPreapprovedHelper } from 'src/app/helpers/filter-preapproved.helper';
import { ClientRequest } from 'src/app/models/client-request.model';
import { Offer } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/pre-approved-products/services/products.service';
import Swal from 'sweetalert2';
import { DialogModalComponent } from '../../shared/components/dialog-modal/dialog-modal.component';
import { type_modal } from 'src/app/helpers/msg-modal.helper';
import { ClientEntryData } from 'src/app/models/client-entry-data.model';

/*############# MAIN COMPONENT #############*/
@Component({
  selector: 'app-clients-form',
  templateUrl: './clients-form.component.html',
  styleUrls: ['../common.components.scss', './clients-form.component.scss']
})
/*##########################################*/

export class ClientsFormComponent implements OnInit {
  @Input() clientData: ClientEntryData;
  @Output() goBackEvent = new EventEmitter();
  @Output() savedEvent = new EventEmitter();

  public document = "";
  public clientRequest: ClientRequest;
  public loading = false;
  public disabled = true;

  public checkItems: String[] = [];
  public radioItems: String[] = [];


  /*############ INTERVAL TIME CLOSED MODAL AND OPEN MODAL IF PRODUCTS HAS TC###########*/
  private timeout: number = 3000;
  /*#################################################*/
  constructor(private service: ProductsService, private helperFilter: FilterPreapprovedHelper, public dialog: MatDialog,) { }


  ngOnInit(): void {
    this.mixProductLogic(this.clientData.offers[0].mixProduct)
    this.clientRequest = new ClientRequest();

  }

  registerClient() {
    this.clientRequest.cedula = this.clientData.document;
    this.clientRequest.email = this.clientData.email;
    this.clientRequest.phone = this.clientData.phone;

    
    const { products, isCreditCard, divice } = this.helperFilter.filterProducts(this.clientData.offers);
    this.clientRequest.productos = [];
    this.clientRequest.productos.push(...products);
    this.loading = true;
    if (this.clientRequest.productos.length === 0) {
      this.loading = false;
      this.openDialog(divice);
      this.requestSaved();
      return null;
    }
    this.service.createClientRequest(this.clientRequest).subscribe((data: any) => {

      Swal.fire({
        icon: 'success',
        title: 'Solicitud registrada correctamente',
        confirmButtonColor: '#1A59A8',
        confirmButtonText: 'Aceptar',

      }).then(() => {
        if (isCreditCard) {
          if (divice) {
            this.openDialog(divice);
          }
        }
      }).then(function () {
        window.location.href = 'https://www.apap.com.do/';
      });

      this.loading = false;
      this.requestSaved();

    }, error => {
      this.loading = false;
      Swal.fire({
        icon: error.error.code !== 500 ? 'info' : 'error',
        title: "Atención",
        text: error.error.message
      })
    });
  }

  public openDialog(data = type_modal.msgRechazarOferta): void {
    this.dialog.open(DialogModalComponent, {
      data
    });
  }

  public isEqualsMixProducts() {
    const keyValue = this.clientData.offers[0].mixProduct;
    return keyValue.split(" ").includes('&');
  }

  public iconSetting(producto: string): string {
    switch (producto) {
      case 'TARJETAS DE CREDITO':
        return 'credit_card';
      case 'TARJETA DE CREDITO':
        return 'credit_card';
      case 'MAS LIMITE':
        return 'credit_score';
      case 'HIPOTECARIO':
        return 'home_work';
      case 'VEHICULAR':
        return 'directions_car';
      default:
        return 'attach_money';
    }
  }

  public toggleCheckBoxes(index: number) {
    this.clientData.offers[index].isChecked =
      !this.clientData.offers[index].isChecked;

    const checkCounter = this.clientData.offers.filter((x) => {
      return x.isChecked == true;
    });

  }



  goBack() {
    this.goBackEvent.emit();
  }

  requestSaved() {
    this.savedEvent.emit();
  }

  public mixProductLogic(mixProducts: string) {
    if (!mixProducts)
      return;
    mixProducts = mixProducts.replace(/\s/g, '');
    if (mixProducts.includes('&')) {
      if (mixProducts.includes('OR')) {
        let splitted = mixProducts.split('OR');
        this.checkItems = splitted[0].split('&');
        this.radioItems = splitted.slice(1);
      } else {
        this.checkItems = mixProducts.split('&')
      }
    } else {
      this.radioItems = mixProducts.split('OR');
    }
  }

  public validateIsCheck(mixCode: string): boolean {
    const res = this.checkItems.find(x => x === mixCode);
    return res ? true : false;
  }

  public disableCheckBox(checked: boolean) {
    if (checked) {
      for (let item of this.checkItems) {
        const checkItem = this.clientData.offers.find(x => x.product.mix_code == item);
        if (checkItem) {
          checkItem.isChecked = false;
        }
      }
    }
    this.validateButtonDisabled();
  }

  public disableRadioButtons(checked: boolean) {
    if (checked) {
      for (let item of this.radioItems) {
        const radioItem = this.clientData.offers.find(x => x.product.mix_code == item);
        if (radioItem) {
          radioItem.isChecked = false;
        }
      }

    }
    this.validateButtonDisabled();
  }

  public validateButtonDisabled() {
    let disabled = true;
    for (let offer of this.clientData.offers) {
      if (offer.isChecked) {
        disabled = false;
      }
    }
    this.disabled = disabled;
  }
}
