import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ClientRequest } from "../../models/client-request.model";
// import * as data from '../../assets/configuration/config.json'
import { IEnvironment } from "../../shared/interfaces/enviroment.interface";
import { AppConfigService } from "../../shared/services/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    private _baseURL?: string;
    // private readonly _config: any = (data as any).default;

    constructor(
        private http: HttpClient,
        private readonly _config: AppConfigService<IEnvironment>,
    ) {
        // this._baseURL = `${this._config?.apiGatewayUrl}api/products/`;
        this._baseURL = `${this._config.getConfiguration()?.apiGatewayUrl}api/products/`;

    }

    public getPreApproveProducts(document: string, email: string, phone:string) {
        const url = this._baseURL + `preapproved`;
        const body = { document: document, email: email, phone };
        return this.http.post(url, body);
    }

    public createClientRequest(data: ClientRequest) {
        const url = this._baseURL + `clients-request/save`
        return this.http.post(url, data);
    }
}
