import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/pre-approved-products/services/products.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DialogModalComponent } from '../../shared/components/dialog-modal/dialog-modal.component';
import { type_modal } from '../../helpers/msg-modal.helper';
import { FormControl, Validators } from '@angular/forms';
import { areaCodeDRValidator } from 'src/app/shared/directives/area-code.validations';
import { ClientEntryData } from 'src/app/models/client-entry-data.model';
import { AuthService } from 'src/app/shared/services/auth.service';


@Component({
	selector: 'app-clients-consult',
	templateUrl: './clients-consult.component.html',
	styleUrls: ['../common.components.scss', './clients-consult.component.scss'],
})
export class ClientsConsultComponent implements OnInit {
	public loading = false;
	public hideForm = true;
	public goNext = false;
	public isRight = false;
	public errorDisclaimer: boolean = false;
	public entryData: ClientEntryData;

	public phoneCtr = new FormControl('',
		[Validators.required,
		Validators.minLength(10),
		Validators.maxLength(10),
		areaCodeDRValidator()])
	constructor(
		private service: ProductsService,
		public dialog: MatDialog,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.entryData = new ClientEntryData();
		this.autenticar();
	}

	public checkItems: String[] = [];
	public radioItems: String[] = [];

	public consultClient() {

		if (!this.isRight) {
			this.errorDisclaimer = true;
			return null;
		}

		if (this.entryData.phone.replace("-", "").length !== 10) {
			Swal.fire({
				icon: 'info',
				title: 'Atención',
				text: 'Para continuar por favor ingrese un número de teléfono válido.',
			});
			return;
		}
		this.loading = true;
		this.service.getPreApproveProducts(this.entryData.document.replace("-", ""), this.entryData.email, this.entryData.phone).subscribe(
			(data: any) => {
				for (let offer of data.content) {
					offer = Object.assign(offer, { isChecked: false });
				}
				this.entryData.offers = data.content;
				this.loading = false;
				this.goNext = true;
			},
			(error) => {

				this.loading = false;
				if (error.error.code === 404) {
					this.openDialog();
				} else {
					Swal.fire({
						icon: error.error.code !== 500 ? 'info' : 'error',
						title: 'Atención',
						text: error.error.message ?? 'Uno de nuestros servicios no está disponible. Por favor intente mas tarde.',
					});
				}
			}
		);
	}

	public toggleCheckDisclaimer(checked: boolean) {
		this.isRight = checked;
		this.errorDisclaimer = !checked;
	}

	public openDialog(): void {
		this.dialog.open(DialogModalComponent, {
			data: type_modal.msgClientNoOffert,
		});
	}
	public openDialogRechazo(): void {
		this.dialog.open(DialogModalComponent, {
			data: type_modal.msgRechazarOferta,
		});
	}
	public goBack() {
		this.goNext = false;
	}

	public savedRequest() {
		this.goBack();
		this.hideForm = true;
		this.entryData = new ClientEntryData();
	}

	private autenticar() {

		this.authService.generateToken().subscribe((data: any) => {
			localStorage.setItem('token', data.content.token);
		})
	}
}
