import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { ClientsConsultComponent } from './pre-approved-products/clients-consult/clients-consult.component';
import { AppRoutes } from './app.routing';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgxLoadingModule } from 'ngx-loading';
import { ClientsFormComponent } from './pre-approved-products/clients-form/clients-form.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { Globals } from './global';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService, appConfigServiceFactory } from './shared/services/config/config.service';
import { NgxMaskModule } from 'ngx-mask';
import { DialogModalComponent } from './shared/components/dialog-modal/dialog-modal.component';
import { Http_Interceptor } from './helpers/http-interceptor';
import { WebRequestFormComponent } from './grupo-ramos/web-request-form/web-request-form.component';
import { SharedModule } from './shared/shared.module';
import { ClientsConsultGrComponent } from './grupo-ramos/clients-consult-gr/clients-consult-gr.component';
import { CookieService } from 'ngx-cookie-service';
import { SendFormErrorDialogComponent } from './grupo-ramos/send-form-error-dialog/send-form-error-dialog.component';

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		ClientsConsultComponent,
		ClientsFormComponent,
		DialogModalComponent,
  		WebRequestFormComponent,
    	ClientsConsultGrComponent,
		SendFormErrorDialogComponent
	],
	imports: [
		SharedModule,
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(AppRoutes),
		ReactiveFormsModule,
		FormsModule,
		NgxMaskModule.forRoot(),
		NgxLoadingModule.forRoot({}),
	],
	providers: [
		CookieService,
		Globals,
		{ provide: APP_INITIALIZER, deps: [AppConfigService], useFactory: appConfigServiceFactory, multi: true },
		{provide: HTTP_INTERCEPTORS, useClass: Http_Interceptor, multi: true}
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
