import { Routes } from "@angular/router";
import { ClientsConsultComponent } from "./pre-approved-products/clients-consult/clients-consult.component";
import { WebRequestFormComponent } from "./grupo-ramos/web-request-form/web-request-form.component";
import { ClientsConsultGrComponent } from "./grupo-ramos/clients-consult-gr/clients-consult-gr.component";

export const AppRoutes: Routes = [
    {
        path: 'clients-consult',
        component: ClientsConsultComponent
    },
    {
        path: 'clients-consult-gr',
        component: ClientsConsultGrComponent
    },
    {
        path: 'web-request-form',
        component: WebRequestFormComponent
    },
    // Ruta por defecto
    {
        path: '',
        redirectTo: 'clients-consult',
        pathMatch: 'full'
    }
]