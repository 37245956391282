<div class="card-container">
    <div class="card">
        <section class="card-body">
            <div class="ctn-title">

                <h1 class="title">Descubre las ofertas de productos que <span>tienes disponible</span></h1>
            </div>

            <form name="offertForm" #offertForm="ngForm" (ngSubmit)="offertForm.valid" class="loading-container">
                <div class="view-pc-list-product">
                    <div class="ctn-list-product">
                        <mat-list class="list" role="list" *ngFor="let product of clientData.offers; let index = index">
                            <mat-list-item class="container-item-list">
                                <ng-container *ngIf="validateIsCheck(product.product.mix_code); else showRadioButton">
                                    <mat-checkbox class="item-list" [(ngModel)]="product.isChecked" [ngModelOptions]="{standalone: true}"
                                        (change)="disableRadioButtons($event.checked)" [checked]="product.isChecked" ngDefaultControl>
                                    </mat-checkbox>
                                </ng-container>
                                <ng-template #showRadioButton>
                                    <mat-radio-button class="item-list" [(ngModel)]="product.isChecked" [ngModelOptions]="{standalone: true}"
                                        (change)="disableCheckBox($event)" [value]="product.isChecked" [checked]="product.isChecked" ngDefaultControl></mat-radio-button>
                                </ng-template>
                                <div class="circle item-list">
                                    <span>
                                        <mat-icon class="icon-product">{{iconSetting(product.product.name)}}</mat-icon>
                                    </span>
                                </div>
                                <span class="name-product item-list">{{product.product.name |titlecase}}</span>
                            </mat-list-item>
                        </mat-list>
                    </div>


                </div>
                <div class="btns">
                    <button type="button" class="back-btn" mat-button (click)="openDialog()">
                        Más tarde
                    </button>
                    <button class="consult-btn" (click)="registerClient()" [disabled]="disabled" type="button"
                        mat-button>
                        Me interesa
                    </button>
                </div>
            </form>
        </section>
    </div>
</div>


<ngx-loading [show]="loading"></ngx-loading>