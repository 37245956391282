import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const phoneValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    if (value.length < 10) {
      return { invalidPhone: true };
    }
    return null;
  };
};
