import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function areaCodeDRValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }
        const areaCode = ['809', '829', '849']
        const str = String(value).substring(0, 3);

        const res = areaCode.filter(x => x.includes(str));
        if (res.length === 0) {
            return { invalidAreaCode: true };
        }
        return null;
    }
}