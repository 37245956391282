import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil, timeout } from 'rxjs';
import { IEnvironment } from 'src/app/shared/interfaces/enviroment.interface';
import { AppConfigService } from 'src/app/shared/services/config/config.service';


@Injectable({
  providedIn: 'root',
})
export class ConsultCatalogsService implements OnDestroy {
  private _baseURL: string;
  private urlAPI: string = 'api/catalogos';
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly http: HttpClient, private readonly _config: AppConfigService<IEnvironment>) {
    this._baseURL = `${this._config.getConfiguration()?.apiGatewayUrl}${this.urlAPI}`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async consultCatalogsRequest(data: string) {
    try {
      const url = this._baseURL + '/filter-by-name';
      const body = {
        catalogName: data,
      };
      const res: any = await this.http
        .post(url, body)
        .pipe(timeout(60000))
        .pipe(takeUntil(this.ngUnsubscribe))
        .toPromise();
      return res;
    } catch (err) {
      console.error(err, `error en endpoint consultCatalogsRequest`);
      return err;
    }
  }
}
