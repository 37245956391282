<div
  *ngFor="let m of messages"
  class="alert alert-danger d-flex align-items-center justify-content-center"
  role="alert"
>
  <div>
    {{ m }}
  </div>
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
