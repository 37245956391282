import { WebRequesFormInterface } from "src/app/interfaces/grupo-ramos-interfaces/web-request-form.interface";

export class SendWebRequestMapper {
    static mappingSendWebRequest (data: any)  {
        return {
            IsClient: data.isClient,
            Cedula: data.dataForm?.cedula,
            Nombres: data.nombres,
            Apellidos: data.apellidos,
            Email: data.dataForm?.email,
            Telefono1: data.dataForm?.phone1,
            Telefono2: data.dataForm?.phone2,
            HaveOportunity: data.haveCCOffer,
            OportunityId: data.oportunity_id,
            IdCicleCut: parseInt(data.dataForm?.idCicleCut),
            IdPct: data.dataForm?.planToChoose,
            Channel: data.channel,
            Comentario: data.dataForm?.comments,
            IngresoMensual: !data.haveCCOffer ? parseInt(data.dataForm?.salaryMonth) : 0,
            IsAccepted: true
        };
    }
}