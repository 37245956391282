<section class="m-5 p-4">
	<form [formGroup]="webRequestDataForm">
		<div class="ctn-border row g-2">
			<div class="title">
				<h4>Datos</h4>
			</div>
			<div class="col-md-12">
				<app-message-toast [messages]="messagesErrors"></app-message-toast>
			</div>
			
			<div class="col-md-6">
				<label class="form-label" for="product"> Producto <span class="text-danger">*</span></label>
				<input
					[ngClass]="{ 'is-invalid': isValidField('product') }"
					class="form-control"
					id="product"
					name="product"
					formControlName="product"
					type="text"
					[attr.disabled]="false"
				/>
				<span class="invalid-feedback">Este campo es obligatorio</span>
			</div>
			<div class="col-md-6">
				<label class="form-label" for="planToChoose"> Plan a elegir <span class="text-danger">*</span></label>
				<select
					[ngClass]="{ 'is-invalid': isValidField('planToChoose') }"
					label="planToChoose"
					name="planToChoose"
					formControlName="planToChoose"
					id="planToChoose"
					class="form-select"
				>
					<option *ngFor="let b of benefits" [ngValue]="b?.id_crm">
						{{ b?.name }}
					</option>
				</select>
				<span class="invalid-feedback">Este campo es obligatorio</span>
			</div>
			<div class="col-md-6">
				<label class="form-label" for="fullName"> Nombre completo <span class="text-danger">*</span></label>
				<input
					[ngClass]="{ 'is-invalid': isValidField('fullName') }"
					class="form-control"
					id="fullName"
					name="fullName"
					formControlName="fullName"
					type="text"
					[attr.disabled]="false"
				/>
				<span class="invalid-feedback">Este campo es obligatorio</span>
			</div>
			<div class="col-md-6">
				<label class="form-label" for="cedula"> Cédula <span class="text-danger">*</span></label>
				<input
					mask="000-0000000-0"
					[ngClass]="{ 'is-invalid': isValidField('cedula') }"
					class="form-control"
					id="cedula"
					name="cedula"
					formControlName="cedula"
					type="text"
					placeholder="000-0000000-0"
					[attr.disabled]="false"
				/>
				<span class="invalid-feedback">Este campo es obligatorio</span>
			</div>
			<div [ngClass]="hasNoOffer? 'col-md-2': 'col-md-3'">
				<label class="form-label" for="phone1"> Teléfono celular <span class="text-danger">*</span></label>
				<input
					[mask]="'000-000-0000'"
					maxlength="12"
					class="form-control"
					id="phone1"
					name="phone1"
					formControlName="phone1"
					[ngClass]="{
						'is-invalid':
						(f.phone1.errors?.invalidAreaCode || f.phone1.errors?.invalidPhone || f.phone1.errors?.required) &&
						f.phone1.touched
					}"
					type="tel"
					placeholder="809-555-5555"
				/>
				<span class="invalid-feedback" *ngIf="f.phone1.errors?.invalidAreaCode">Código de área inválido (Ej. 809, 829, 849).</span>
				<span class="invalid-feedback" *ngIf="f.phone1.errors?.invalidPhone">Digite un número telefónico válido</span>
				<span class="invalid-feedback" *ngIf="f.phone1.errors?.required">Este campo es obligatorio</span>
			</div>
			<div [ngClass]="hasNoOffer? 'col-md-2': 'col-md-3'">
				<label class="form-label" for="phone2"> Teléfono casa </label>
				<input
					[mask]="'000-000-0000'"
					maxlength="12"
					class="form-control"
					[ngClass]="{
						'is-invalid':
						(f.phone2.errors?.invalidAreaCode || f.phone2.errors?.invalidPhone || f.phone2.errors?.required) &&
						f.phone2.touched
					}"
					id="phone2"
					name="phone2"
					formControlName="phone2"
					type="tel"
					placeholder="809-555-5555"
				/>
				<span class="invalid-feedback" *ngIf="f.phone2.errors?.invalidAreaCode">Código de área inválido (Ej. 809, 829, 849).</span>
				<span class="invalid-feedback" *ngIf="f.phone2.errors?.invalidPhone">Digite un número telefónico válido</span>
			</div>
			<div class="col-md-2" *ngIf="hasNoOffer">
				<label class="form-label" for="salaryMonth">Ingresos<span class="text-danger">*</span></label>
				<input
				formControlName="salaryMonth"
				[ngClass]="{ 'is-invalid': (f.salaryMonth.errors?.min ||  f.salaryMonth.errors?.required) &&
				f.salaryMonth.touched }"
				prefix="RD$"
				mask="separator.2"
				thousandSeparator=","
				class="form-control"
				id="salaryMonth"
				name="salaryMonth"
				type="text"
					(keypress)="keyPress($event)"

				/>
				<span class="invalid-feedback">Este campo es obligatorio</span>
				<!-- <span class="invalid-feedback" *ngIf="f.salaryMonth.errors?.min">Digite una cantidad valida</span> -->
			</div>
			<div [ngClass]="hasNoOffer? 'col-md-2': 'col-md-3'">
				<label class="form-label" for="idCicleCut"> Fecha de corte <span class="text-danger">*</span> </label>
				<select
					[ngClass]="{ 'is-invalid': isValidField('idCicleCut') }"
					label="idCicleCut"
					name="idCicleCut"
					formControlName="idCicleCut"
					id="idCicleCut"
					class="form-select"
				>
					<option *ngFor="let cc of cicleCuts" [ngValue]="cc?.ciclo_corte">
						{{ cc?.ciclo_corte }}
					</option>
				</select>
				<span class="invalid-feedback">Este campo es obligatorio</span>
			</div>
			<div class="col-md-3">
				<label class="form-label" for="email"> Email <span class="text-danger">*</span> </label>
				<input
					[ngClass]="{ 'is-invalid': (f.email.errors?.email || f.email.errors?.required) && f.email.touched }"
					class="form-control"
					id="email"
					name="email"
					formControlName="email"
					type="email"
					placeholder="example@mail.com"
				/>
				<span class="invalid-feedback" *ngIf="f.email.errors?.required">Este campo es obligatorio</span>
				<span class="invalid-feedback" *ngIf="f.email.errors?.email">Por favor, digite un correo valido</span>
			</div>
			<div class="col-md-12">
				<label class="form-label" for="comments"> Comentario </label>
				<textarea
					appSpecialChart
					[ngClass]="{ 'is-invalid': isValidField('comments') }"
					class="form-control"
					id="comments"
					name="comments"
					formControlName="comments"
					placeholder="Ingrese comentario"
					maxlength="255"
					(keyup)="countCharacters($event)"
				></textarea>
				<span class="text-primary">{{ charactersRemaining }}/255</span>
				<span class="invalid-feedback">Este campo es obligatorio</span>
			</div>
			<div class="btns">
				<button class="back-btn" type="button" mat-button (click)="goBack()">
					Regresar
				</button>
				<button [disabled]="isLoading" class="consult-btn" type="submit" mat-button (click)="onSubmit()">
					<span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
					<span *ngIf="isLoading"> Enviando... </span>
					<span *ngIf="!isLoading"> Enviar </span>
				</button>
			</div>
		</div>
	</form>
</section>

<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }"></ngx-loading>
  