/**
 * Author: Odalmi R. Paulino Peña 
 * (simplemente pongo mi nombre para ayuda para el futuro dev que trabajara esta parte).
 * (creo que se puede mejorar más la implementación, pero por ahora no tengo el intelecto para 
 * hacerlo).
 * 
 * @const {Object} type_modal - es un objecto que ayuda a la creación de los DialogModals,
 *  estas @constantes dentro de el objeto @type_modal son simplemente el contenido
 *  de cada DialogModal en la SPA.
 * 
 *  @img: corresponde a las imagenes que estan en la carpeta assets.
 *  @title: titulo destinado al DialogModal correspondiente.
 *  @id: determina el tipo de id que rederizara el template dialog-modal.component.html
 *  Y hay una 4ta @constante {msg o divice}, pero esta esta ligada en concreto con el
 *  archivo detect-divice.helper.ts y en el modelo dialog-modal.model.ts.
 * */ 

export const type_modal = {
    msgMobile: {
        id: 1,
        img: `../../../assets/img/64741-isometric-app-animation.gif`,
        title: `¿Quieres recibir tu nueva tarjeta de crédito en menos tiempo?
                <br/>
                Descarga APAPPMóvil`,
    },
    msgClientNoOffert: {
        id: 2,
        img: `../../../assets/img/41252-searching-radius.gif`,
        title: `Actualmente no posees ninguna oferta disponible. <br>
                Para continuar, selecciona la opción de tu preferencia.`,
    },

    msgRechazarOferta: {
        id: 3,
        img: `../../../assets/img/91537-message-conversation.svg`,
        title: `¿Estás seguro que deseas abandonar el proceso?`,
    },

    msgGoToConsultFrm: {
        id: 4,
        img: `../../../assets/img/91537-message-conversation.svg`,
        title: `¿Estás seguro que deseas volver a la pantalla de consulta?`,
    }
}
