import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetectDivice } from 'src/app/helpers/detect-divice.helper';
import { type_modal } from '../../../helpers/msg-modal.helper';
import { ContentModal } from '../../../models/dialog-modal.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ContentModal,
    public dialogRef: MatDialogRef<DialogModalComponent>,
    public otherDialog: MatDialog,
    private readonly router: Router,
    ) {}

  ngOnInit(): void {
  }
  
  public onNoClick(): void {
    this.dialogRef.close();
  }
  //No se esta utilizando
  public openAgain() {
    const [title, msg] = new DetectDivice().typeDivice();
  
    this.dialogRef.close();
    this.otherDialog.open(DialogModalComponent,
    {
      data: {
        id: 1,
        img: type_modal.msgMobile.img,
        title,
        msg
      }
      
    });
  }
  

  public gotToBeClient(): void {
    window.location.href='https://www.apap.com.do/hazte-cliente/';
  }

  public goToHomePage(): void{
    window.location.href='https://www.apap.com.do/';
  }

  public goToConsultForm(): void{
    this.router.navigate(['/clients-consult-gr'], { replaceUrl: true });
    this.dialogRef.close();
  }
}
