import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appMaskPhone]',
})
export class MaskPhoneDirective {
	@HostListener('input', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;

		let trimmed = input.value.replace(/\s+/g, '');

		if (trimmed.length > 12) {
			trimmed = trimmed.substring(0, 12);
		}

		trimmed = trimmed.replace(/-/g, '');

		let numbers = [];
		numbers.push(trimmed.substring(0, 3));
		if (trimmed.substring(3, 6) !== '') {
			numbers.push(trimmed.substring(3, 6));
		}
		if (trimmed.substring(6, 12) != '') {
			numbers.push(trimmed.substring(6, 12));
		}
		input.value = numbers.join('-');
	}
}