import { Component, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UrlInterceptor } from './helpers/url-interceptor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy{
  title = environment.title;
  apiURL = environment.apiURL;

  constructor(private readonly urlInterceptor: UrlInterceptor){}
  ngOnDestroy(): void {
    this.urlInterceptor.destroyCookie()
  }
  
}
