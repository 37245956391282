import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navBarSubTitle = "";
  navBarImg = "";
  currentUrl = this.location.path();

  constructor(private location: Location) { 
    // Obtén la URL actual
    if (this.currentUrl === '/clients-consult-gr' || this.currentUrl === '/web-request-form') {
      this.navBarSubTitle = "Estás en un entorno seguro de la Asociación Popular de Ahorros y Prestamos";
      this.navBarImg = "./assets/img/img-grupo-ramos/logo-nav-gr.svg"
    } else {
      this.navBarSubTitle = "Estás en un entorno seguro de La Asociación Popular"
      this.navBarImg = "./assets/img/logo-web.svg"
    }
  }

  ngOnInit(): void {
  }

}


