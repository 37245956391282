import { Injectable } from "@angular/core";
import { ProductDto } from "../models/client-request.model";
import { Offer } from "../models/product.model";
import { type_modal } from './msg-modal.helper';
import { DetectDivice } from './detect-divice.helper';

interface filterProducts {
    products: ProductDto[],
    isCreditCard: boolean,
    divice: any
}
@Injectable({
    providedIn: 'root'
})
export class FilterPreapprovedHelper {
    private prods: filterProducts
    public titleAlternative = `¿Quieres recibir tu nueva tarjeta de crédito en menos tiempo? Descarga APAPPMóvil en Google Play o App Store y obtenla. `
    constructor() { }

    public filterProducts(productos: Offer[]): filterProducts {
        const [,msg] = new DetectDivice().typeDivice();
        this.prods = {
            products: [],
            isCreditCard: false,
            divice: {
                title: (msg !== '')? type_modal.msgMobile.title: this.titleAlternative,
                img: type_modal.msgMobile.img,
                msg,
                id: 1,
            }
        }
       
        for (let item of productos) {
            const p = new ProductDto();
            p.offerId = item.offerId;
            p.producto = item.product.name;
            if (item.isChecked) {

                //Ya esta parte no va, Por parte del a PO 

                // if (item.aprobacion === 'PRE-APROBADO' && item.producto === 'TARJETA DE CREDITO') {
                //     this.prods.isCreditCard = true;
                //     break;
                // }
                this.prods.products.push(p);
            }
        }

        return this.prods;
    }
}