import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './config/config.service';
import { IEnvironment } from '../interfaces/enviroment.interface';
// import * as data from '../../assets/configuration/config.json'


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _baseURL?: string;
  // private readonly _config: any = (data as any).default;

  constructor(
    private http: HttpClient,
    private readonly _config: AppConfigService<IEnvironment>,

  ) {
    // this._baseURL = `${this._config?.apiGatewayUrl}api/auth/`;
    this._baseURL = `${this._config.getConfiguration()?.apiGatewayUrl}api/auth/`;

  }

  public generateToken() {
    const url = this._baseURL + `generate-token`;
    return this.http.get(url);
  }


}
