import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from "../shared/enums/routes.enum";
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class UrlInterceptor {
    data!: any;
    constructor(
        private readonly activatedRoute: ActivatedRoute, 
        private readonly location: Location, 
        private readonly cookieSVC: CookieService
        ){}

    cookiesManager(data: any){
        if(data === 'sirena'){
            this.cookieSVC.set('channel', 'WEB-SIRENA', {expires: 1} );
        }else {
            this.cookieSVC.set('channel', 'WEB-APAP',  {expires: 1} );
        }
        return this.cookieSVC.get('channel');
    }

    destroyCookie(){
        this.cookieSVC.delete('channel');
        console.log('se destruyeron las coockies')
    }

   async getParamsUrl(){
        this.activatedRoute.queryParams.subscribe(params => {
            this.data = params['chn'] || null
        });
        this.data = this.cookiesManager(this.data);
        this.location.go(RoutesEnum.ClientConsultGR);
        
        return this.data;
    }
}
