<div class="card-container">
	<div class="card">
		<section class="card-body">
			<span class="alert alert-danger" *ngIf="showBannerAlert">{{ message }}</span>
			<form [formGroup]="consultDataForm">
				<div class="title py-4">Completa tus datos</div>
				<span class="sub-title">Consulta el estatus aquí:</span>
				<div class="row justify-content-center">
					<div class="ctn-input-disclaimer">
						<mat-form-field class="item-container inputCustomerId" appearance="outline">
							<mat-label>
								Cédula de identidad
							</mat-label>
							<input
								formControlName="customerId"
								[ngClass]="{ 'is-invalid': isValidField('customerId') }"
								id="customerId"
								name="customerId"
								type="text"
								placeholder="Cédula de identidad"
								[mask]="'000-0000000-0'"
								matInput
							/>
							<mat-icon matPrefix>badge</mat-icon>
							<mat-error>
								Este campo es obligatorio
							</mat-error>
						</mat-form-field>
						<app-disclaimer 
							[errorDisclaimer]="errorDisclaimer"
							(toggleCheckDisclaimer)="toggleCheckDisclaimer($event)"
						></app-disclaimer>
					</div>
				</div>
				<div class="btns">
					<button class="back-btn" type="button" mat-button (click)="openDialogRechazo()">
						Regresar
					</button>
					<button 
						[disabled]="consultDataForm.invalid || isLoading"
						class="consult-btn" 
						(click)="onSubmit()"
						type="submit" 
						mat-button
					>
						<span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
						<span *ngIf="isLoading"> Buscando... </span>
						<span *ngIf="!isLoading"> Siguiente </span>
					</button>
				</div>
			</form>
		</section>
	</div>
</div>
  