import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientData } from 'src/app/interfaces/grupo-ramos-interfaces/client-data.interface';
import { SendWebRequestMapper } from 'src/app/grupo-ramos/mapper/send-web-resquest.map';
import { areaCodeDRValidator } from 'src/app/shared/directives/area-code.validations';
import { phoneValidator } from 'src/app/shared/directives/phone.validations';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { ConsultCatalogsService } from '../service/consult-catalogs.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateFormShortService } from '../service/create-form-short.service';
import { SendFormErrorDialogComponent } from '../send-form-error-dialog/send-form-error-dialog.component';
import { PersonData } from 'src/app/interfaces/grupo-ramos-interfaces/person-data.interface';
import { DialogModalComponent } from 'src/app/shared/components/dialog-modal/dialog-modal.component';
import { type_modal } from '../../helpers/msg-modal.helper';
import { WebRequesFormInterface } from 'src/app/interfaces/grupo-ramos-interfaces/web-request-form.interface';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-web-request-form',
    templateUrl: './web-request-form.component.html',
    styleUrls: ['./web-request-form.component.scss']
})
export class WebRequestFormComponent implements OnInit {

    dataForm!: WebRequesFormInterface;
    webRequestDataForm!: FormGroup;
    clientData: ClientData;
    messagesErrors: Array<string> = [];
    benefits: any;
    cicleCuts: any;
    isRight = true;
    errorDisclaimer!: boolean;
    data: any;
    personData!: PersonData;
    charactersRemaining = 0;
    hasNoOffer = false;
    isLoading = false;
    constructor(
        private readonly formBuild: FormBuilder,
        private readonly router: Router,
        private readonly location: Location,
        private readonly dialog: MatDialog,
        private readonly consultCtlg: ConsultCatalogsService,
        private readonly createFormShortService: CreateFormShortService
    ) {
        if(!!this.router.getCurrentNavigation()?.extras?.state?.data){
            this.data = this.router.getCurrentNavigation()?.extras?.state?.data;
            this.personData = {...this.data.personData, idDocument: this.data.idDocument, clientData: this.data.clientData}
        }

        this.clientData = this.data?.clientData;
        
        this.hasNoOffer = !this.data?.haveCCOffer; 
        if (this.data == undefined) {
            this.router.navigate([RoutesEnum.ClientConsultGR], { replaceUrl: true });
        }
        this.webRequestDataForm = this.initForm();
        if(this.hasNoOffer) {
            this.webRequestDataForm.controls['salaryMonth'].setValidators([Validators.required, Validators.min(1)]);
            
        }
    }

    public goBack(): void {
		this.dialog.open(DialogModalComponent, {
			data: type_modal.msgGoToConsultFrm,
		});
	}

    keyPress(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
      }
    async ngOnInit(): Promise<void> {
        
        let res = await this.consultCtlg.consultCatalogsRequest('pct_beneficios');
        
        if (res?.code == 200) {
            this.benefits = res?.content?.pct_beneficios;
        } else {
            this.benefits = [];
            this.messagesErrors.push('La información no cargó correctamente. Vuelve  a intentarlo.');
        }

        res = await this.consultCtlg.consultCatalogsRequest('dia_corte');
        
        if (res?.code == 200) {
            this.cicleCuts = res?.content?.dia_corte;
        } else {
            this.cicleCuts = [];
            this.messagesErrors.push('La información no cargó correctamente. Vuelve  a intentarlo.');
        }
    }

    countCharacters(e: any) {
        this.charactersRemaining = e.target.value.length;
    }

    onSubmit() {
        if (this.webRequestDataForm.invalid) {
            for (const control of Object.keys(this.webRequestDataForm.controls)) {
                this.webRequestDataForm.controls[control].markAsTouched();
            }
            return;
        }
        this.sendForm(this.webRequestDataForm.value);
    }

    openErrorDialog(message: string): void {
        const dialogRef = this.dialog.open(SendFormErrorDialogComponent, {
            width: '250px',
            data: message,
        });
    }

    async sendForm(dataForm: any): Promise<void> {
        this.isLoading = true;

        const payload = SendWebRequestMapper.mappingSendWebRequest({
            dataForm,
            haveCCOffer: this.data.haveCCOffer,
            id_client: this.data.isClient ? this.data.clientData.id : null,
            isClient: this.data.isClient,
            disclaimer: this.isRight,
            oportunity_id: this.data.haveCCOffer ? this.data?.offer[0]?.offerId : null,
            nombres: this.personData?.personaFisica?.nombres,
            apellidos: this.personData?.personaFisica?.apellidos,
            channel: this.data.channel
        });
        this.createFormShortService.createClientRequest(payload).subscribe((data: any) => { 
            Swal.fire({
                icon: 'success',
                title: 'Solicitud registrada correctamente',
                confirmButtonColor: '#1A59A8',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.router.navigate([RoutesEnum.ClientConsultGR], { replaceUrl: true });
                }
            });
            this.isLoading = false;
        }, error => {
            Swal.fire({
                icon: error.error.code !== 500 ? 'info' : 'error',
                allowOutsideClick: false,
                title: "Atención",
                text: 'Se produjo un error al realizar la solicitud,  verifica que los campos requeridos estén completos.'
            });
            this.isLoading = false;
        });
    }

    isValidField(name: string): boolean {
        const fieldName = this.webRequestDataForm.get(name);
        return !!fieldName?.invalid && (!!fieldName?.touched);
    }
    
    get f() {
        return this.webRequestDataForm.controls;
    }

    initForm(): FormGroup {
        return this.formBuild.group({
            product: ['VISA GOLD SIRENA', [Validators.required]],
            planToChoose: ['', [Validators.required]],
            fullName: [`${this.personData?.personaFisica?.nombres} ${this.personData?.personaFisica?.apellidos}` || ''],
            cedula: [this.personData?.idDocument || '', [Validators.required]],
            phone1: [
                this.clientData?.phone1 || '',
                [Validators.required, Validators.minLength(10), areaCodeDRValidator(), phoneValidator()]
            ],
            phone2: [this.clientData?.phone2 || '', [Validators.minLength(10), areaCodeDRValidator(), phoneValidator()]],
            salaryMonth: [''],
            idCicleCut: ['', [Validators.required]],
            email: [this.clientData?.email || '', [Validators.required, Validators.email]],
            comments: [''],
        });
    }
}
