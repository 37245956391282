const  diviceType = {
    android: [`Descarga APAPPMóvil en Google Play y Hazte Cliente con la Cuenta de Ahorro Digital APAP.`, `<a 
                    href="https://play.google.com/store/apps/details?id=com.apapmovilprod&hl=es_DO" 
                    target="_blank" rel="noopener noreferrer">
                    <img width="150px" src="../../../assets/img/googleplay.svg" alt="Play Store"/>
                    </a>`],
    ios: [`Descarga APAPPMóvil en App Store y Hazte Cliente con la Cuenta de Ahorro Digital APAP.`, `<a 
                href="https://apps.apple.com/do/app/apapp-movil/id1073508748?l=en" 
                target="_blank" rel="noopener noreferrer">
                <img width="150px" src="../../../assets/img/appstore.svg" alt="App Store"/>
            <a>`],
    pc: [`Descarga APAPPMóvil en Google Play o App Store y Hazte Cliente con la Cuenta de Ahorro Digital APAP.`,'']
}
export class DetectDivice {

    constructor(

        private ua: string = navigator.userAgent,
        private regExpAndroid = /android/i,
        private regExpIos = /iPad|iPhone|iPod/
    ) { }

    public typeDivice(): Array<string> {
        if (this.regExpAndroid.test(this.ua)) {
            return diviceType.android;
        }

        if (this.regExpIos.test(this.ua)) {
            return diviceType.ios;
        }
        return diviceType.pc
    }


}
