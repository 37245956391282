import { NgModule } from "@angular/core";
import { MaskIdentificationDirective } from "./directives/mask-identification.directive";
import { MaskPhoneDirective } from "./directives/mask-phone.directive";
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { MaterialModule } from "./material/material.module";
import { CommonModule } from "@angular/common";
import { MessageToastComponent } from "./components/message-toast/message-toast.component";
import { OffertDialogComponent } from "./components/offert-dialog/offert-dialog.component";

@NgModule({
  declarations: [
    MaskIdentificationDirective,
    MaskPhoneDirective,
    DisclaimerComponent,
    MessageToastComponent,
    OffertDialogComponent,
  ],
  imports: [MaterialModule, CommonModule],
  exports: [
    MaterialModule,
    MaskIdentificationDirective,
    MaskPhoneDirective,
    DisclaimerComponent,
    MessageToastComponent,
  ],
})
export class SharedModule {}
