import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.scss'],
})
export class MessageToastComponent {
  @Input() messages!: Array<string>;
  constructor() {}
}
