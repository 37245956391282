import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import Swal from "sweetalert2";

@Injectable()
export class Http_Interceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('token');
        if (token) {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
        }
        return next.handle(req).
            pipe(
                tap(
                    (event: HttpEvent<any>) => { }, (err: Response) => {
                        if (err.status === 401) {
                            localStorage.clear();
                            Swal.fire({
                                icon: 'info',
                                title: 'Atención',
                                text: 'Uno de nuestros servicios no se encuentra disponible. Por favor intente más tarde',
                            });
                            // window.location.reload();
                        }
                    }))
    }

}