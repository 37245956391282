import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import config from '../../../../assets/configuration/config.json'
@Injectable({
  providedIn: 'root',
})
export class AppConfigService<T> {
  private config: T | null = null;

  constructor(private http: HttpClient) { }

  public async loadConfig() {
    try {
      if (this.config) return this.config;

      const result: any = await firstValueFrom(this.http.get(`./assets/configuration/config.json`));

      if (result) {
        this.config = result;
      }
      return this.config;
    } catch (e) {
      return this.config;
    }
  }

  public getConfig() {
    return config;
  }

  public getConfiguration(): T | null {
    return this.config;
  }

  isOnSecureMode() {
    return window.location.protocol === 'https:';
  }
}

export const appConfigServiceFactory = (appConfig: AppConfigService<any>) => {
  return () => appConfig.loadConfig();
}